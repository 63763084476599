<template>
    <div v-if="block_data.hide == 0 || (builder_mode == true && preview_mode == false)" class="block-design position-relative">
      <div :class="'block block-voucher block-'+block_data.block_id" :style="[(builder_mode == true && preview_mode == false) ? { 'pointer-events': 'none', 'min-height': '48px' } : '']">
        <h3 v-if="block_title !== ''">{{block_title}}</h3>
        <pre v-if="block_description !== ''" v-html="block_description" style="white-space: pre-wrap; font-family: var(--mypage-paragraph-font-family);font-weight: var(--mypage-paragraph-font-weight);line-height: var(--mypage-paragraph-line-height);color: var(--mypage-paragraph-color);font-size: var(--mypage-paragraph-font-size);"></pre>

        <div class="search-wrapper">
          <div class="input-group" style="font-family:font-family: var(--mypage-paragraph-font-family);font-weight: var(--mypage-paragraph-font-weight);line-height: var(--mypage-paragraph-line-height);color: var(--mypage-paragraph-color);font-size: 13px">
              <input type="text" class="form-control rounded-pill rounded-end input-search" :id="'input-search-voucher-'+block_data.block_id" :placeholder="$t('search_voucher_placeholder')" aria-describedby="button-search" @keyup.enter="search_voucher" v-on:keyup="input_search_change" style="line-height:1; font-size: calc(var(--mypage-paragraph-font-size)*var(--mypage-paragraph-ratio))px;">
            <button class="btn btn-sm btn-outline-secondary rounded-pill rounded-start px-3" type="button" @click="search_voucher">
              <i class="bi bi-search"></i>
            </button>
          </div>
        </div>

        <div v-if="items_to_show.length > 0" :class="'mt-2 p-1 voucher-'+block_data.block_id" style="background-color:#f5f5f5; border-radius:4px;">
          <div class="voucher-wrapper" :style="voucher_wrapper_style">
            <div
              v-for="(item, index) in items_to_show" 
              v-bind:key="index+'-'+item.voucher_id" 
              :class="(index  > number_items_show - 1) ? 'voucher-item h-100 voucher-item-' + item.voucher_id + ' d-none':'voucher-item h-100 voucher-item-' + item.voucher_id"
            >
              <div class="d-flex flex-column h-100">
                <div class="d-flex flex-row" style="overflow: hidden;">
                  <div style="margin-right: 4px;">
                    <a :href="linkContent(item)" :target="linkTarget(item)" class="">
                      <div style="width:60px; height: 60px;">
                        <v-lazy-image class="voucher-image" :src="item.image_url"/>
                      </div>
                    </a>
                  </div>
                  <div class="w-100" style="font-family: var(--mypage-paragraph-font-family); color: var(--mypage-paragraph-color);">
                    <div class="w-100" @click="detailVoucher(item)">
                      <div style="font-size: 11px;">
                        {{$t('voucher_code')}}: <span class="fw-bold" style="font-size: 13px;">{{item.voucher_code}}</span>
                      </div>
                      <div class="w-100" style="font-size:14px; font-weight:700; color: #F5222D;">
                        {{$t('voucher_value')}}: {{item.voucher_value}}<span class="" v-if="item.voucher_type === '%'">{{item.voucher_type}}</span><span class="ms-1" v-if="item.voucher_type !== '%'">{{item.voucher_type}}</span>
                      </div>
                      <div class="w-100" style="font-size: 11px;" v-if="item.datetime_end !== ''">
                        {{$t('expired')}}: {{formatDatetimeDisplay(item.datetime_end)}}
                      </div>
                      <!-- <div class="w-100 mt-1" style="font-size: 11px;">
                        {{$t('detail')}}<i class="bi bi-info-circle ms-1"></i>
                      </div> -->
                    </div>
                  </div>
                </div>

                <div class="mt-1" style="font-family: var(--mypage-paragraph-font-family); color: var(--mypage-paragraph-color);">
                  <div style="font-size: 15px; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden;">
                    {{item.voucher_name}}
                  </div>
                  <div style="font-size: 11px; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; word-break: break-word; overflow: hidden;">
                    {{item.voucher_description}}
                  </div>
                </div>

                <div class="mt-auto">
                  <button 
                    :class="'btn-copy-voucher w-100 mt-1 text-center btn-copy-voucher-' + '-' + item.voucher_id" @click="detailVoucher(item)">{{$t('copy_voucher_2')}}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button v-if="items_to_show.length > number_items_show" 
            style="font-family: var(--mypage-paragraph-font-family); color:var(--mypage-button-text-color); border: var(--mypage-button-border-width) solid var(--mypage-button-border-color); border-radius: var(--mypage-button-border-radius); font-size: var(--mypage-button-text-size); font-weight: var(--mypage-button-text-font-weight); line-height: var(--mypage-button-text-line-height); background-color: var(--mypage-button-background-color); box-shadow: var(--mypage-button-box-shadow);"
            :class="'btn-load-more-voucher w-100 mt-3 py-2 text-center btn-load-more-voucher-' + block_data.block_id" @click="show_all(block_data.block_id)">{{content_obj.button_more_text}}
          </button>
        </div>
        <div v-if="no_results == true" class="text-center not-found-voucher mt-1">
          {{ $t('no_results') }}
        </div>
        <div class="modal modal-voucher-detail" :id="'voucherDetailModal-'+block_data.block_id" data-bs-keyboard="false"  aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable animate__animated animate__zoomIn">
              <div class="modal-content border-0" style="max-width: var(--max-width-custom); margin: 0 auto;">
                <div class="modal-header">
                  <div class="modal-title fs-5">{{$t('voucher')}}</div>
                  <button type="button" class="btn-close" @click="closeDetailVoucherModal()" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="d-flex flex-row">
                    <div>
                      <v-lazy-image class="voucher-image" :src="current_view_voucher.image_url" style="width:80px; height:80px; object-fit: cover; display: block; border-radius:4px;"/>
                    </div>
                    <div class="ms-2">
                      {{current_view_voucher.voucher_name}}
                    </div>
                  </div>
                  <div style="height:1px; border-top: 1px #c8c8c8 dashed; margin: 0.75rem 0;">
                  </div>

                  <div class="d-flex flex-row justify-content-between">
                    <div>
                      <i class="bi bi-tag"></i> {{$t('voucher')}}
                    </div>
                    <div style="font-weight:700; color: #F5222D;">
                      {{current_view_voucher.voucher_value}}<span v-if="current_view_voucher.voucher_type === '%'">{{current_view_voucher.voucher_type}}</span><span class="ms-1" v-if="current_view_voucher.voucher_type !== '%'">{{current_view_voucher.voucher_type}}</span>
                    </div>
                  </div>
                  <div class="input-group mt-1">
                    <input type="text" class="form-control text-center" :placeholder="current_view_voucher.voucher_code" aria-label="" aria-describedby="button-copy-voucher-modal-detail" disabled>
                    <button type="button" id="button-copy-voucher-modal-detail" class="btn btn-outline-secondary btn-copy" @click="copyVoucher(current_view_voucher.voucher_code, 'voucherDetailModal-'+block_data.block_id)"><i class="bi bi-clipboard"></i></button>
                  </div>
                  <div style="font-size:13px;" class="mt-2 d-flex align-items-center" v-if="current_view_voucher.datetime_end !== ''">
                    <i class="bi bi-clock me-1"></i>{{$t('expired')}}: <span class="fw-bold ms-2">{{formatDatetimeDisplay(current_view_voucher.datetime_end)}}</span>
                  </div>

                  <div class="mt-3 d-flex align-items-center fw-bold">
                    {{$t('voucher_description')}}
                  </div>

                  <div class="mt-2">
                    <pre style="white-space: pre-wrap;" v-if="current_view_voucher.voucher_description !== ''" v-html="current_view_voucher.voucher_description"></pre>
                  </div>

                </div>
                <div class="modal-footer flex-nowrap">
                  <button type="button" class="btn-copy-voucher-lg w-50 p-2" @click="copyVoucher(current_view_voucher.voucher_code, 'voucherDetailModal-'+block_data.block_id)">{{$t('copy_voucher')}}</button>
                  <button type="button" class="btn-copy-voucher-outline-lg w-50 p-2" @click="gotoPage()">{{$t('open_page')}}</button>
                </div>
              </div>
            </div>
          </div>
        
      </div>
      <div v-if="preview_mode == false && block_data.hide == 1" class="position-absolute hidden-mark">
        <div class="hidden-mark-inner d-flex flex-row justify-content-center align-items-center">
          <div><span v-html="icon_svg.bi_eye_slash()"></span><span class="ps-2">{{$t('block_is_hidden')}}</span></div>
        </div>
      </div>
      <div v-if="builder_mode == true && preview_mode == false" class="position-absolute block-mask" v-on:dblclick="editBlock()">
      </div>
      <div v-if="builder_mode == true && preview_mode == false" class="position-absolute btn-edit btn-edit-block">
        <div class="dropdown">
          <button type="button" class="btn d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
            <span v-html="icon_svg.bi_three_dots_vertical()"></span>
          </button>
          <ul class="dropdown-menu dropdown-menu-end dropdown-menu-dark">
            <li><button type="button" class="dropdown-item d-flex align-items-center" @click="editBlock()"><span v-html="icon_svg.bi_pencil_square()"></span><span class="ps-2">{{$t('edit')}}</span></button></li>
            <li><hr class="dropdown-divider"></li>
            <li v-if="block_data.hide == 0"><button type="button" class="dropdown-item d-flex align-items-center" @click="toogleBlock()"><span v-html="icon_svg.bi_eye_flash_fill()"></span><span class="ps-2">{{$t('hide')}}</span></button></li>
            <li v-if="block_data.hide == 1"><button type="button" class="dropdown-item d-flex align-items-center" @click="toogleBlock()"><span v-html="icon_svg.bi_eye()"></span><span class="ps-2">{{$t('show')}}</span></button></li>
            <li><hr class="dropdown-divider"></li>
            <li v-if="block_index > 0"><button type="button" class="dropdown-item" @click="moveBlockUp()"><span v-html="icon_svg.bi_arrow_up()"></span><span class="ps-2">{{$t('move_up')}}</span></button></li>
            <li v-if="block_index > 0"><hr class="dropdown-divider"></li>
            <li v-if="block_index < number_of_blocks-1"><button type="button" class="dropdown-item" @click="moveBlockDown()"><span v-html="icon_svg.bi_arrow_down()"></span><span class="ps-2">{{$t('move_down')}}</span></button></li>
            <li v-if="block_index < number_of_blocks-1"><hr class="dropdown-divider"></li>
            <li><button type="button" class="dropdown-item" @click="deleteBlock()"><span v-html="icon_svg.bi_trash()"></span><span class="ps-2">{{$t('delete')}}</span></button></li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js"
import icon_svg from "@/utils/icon_svg.js"
import utils from "@/utils/utils.js"
import { mapGetters } from 'vuex'
import predefined from "@/utils/predefined.js"
import Vue from "vue"
import VLazyImage from "v-lazy-image/v2"
const moment = require('moment')
export default {
    name: 'BlockVoucher',
    props: ['block_index', 'block_data'],
    components: {
      VLazyImage
    },
    data: () => ({
      icon_svg: icon_svg,
      predefined: predefined,
      block_title: '',
      block_description: '',
      block_content: '',
      content_obj: {},

      items_to_show: [],
      number_items_show: 8,
      voucher_wrapper_style: 'display: grid; grid-template-columns: repeat(2, minmax(0px, 1fr));grid-column-gap: 0.25rem;grid-row-gap: 0.5rem; align-items: center;',
      current_view_voucher: {},
      no_results: false,
    }),
    created(){
      this.initData()
    },
    computed: {
      ...mapGetters({
        preview_mode: 'builder/previewMode',
        builder_mode: 'builder/builderMode',
        block_ids: 'mypage/getBlockIds',
        number_of_blocks: 'mypage/numberOfBlocks',
        blocks: 'mypage/getBlocks'
      })
    },
    methods: {
      initData(){
        this.block_title = this.block_data.block_title
        this.block_description = this.block_data.block_description
        this.block_content = utils.unescapeHtml(this.block_data.block_content)
        
        var content_obj =  JSON.parse(JSON.stringify(this.predefined.block_voucher_obj()))
        try {
          var current_data = JSON.parse(this.block_content)
          for ( var property in current_data ) {
            content_obj[property] = current_data[property]
          }
        } catch(e) {
            console.log(e)
        }
        Vue.set(this, 'content_obj', content_obj)
        this.current_view_voucher = this.predefined.voucher_obj()
        this.voucherToShow()
      },
      formatDatetimeDisplay(datetime_string){
        if (datetime_string !== ''){
          let ts = moment(datetime_string, "DD-MM-YYYY HH:mm").valueOf()
          return moment(ts).format('DD-MM-YYYY H:mm')
        }else{
          return ''
        }
      },
      voucherToShow(){
        Vue.set(this, 'items_to_show', [])
        if (this.content_obj.hide_expired_voucher == true){
          let current_time = Date.now()
          // console.log(current_time)
          for(let i = 0; i < this.content_obj.items.length ; i++){
              const item = this.content_obj.items[i]
              var time = ''
              if (item.datetime_end !== ''){
                time = item.datetime_end.split(' ')
              }
              var second = 0
              if (time === "23:59"){ second = 59 }

              let item_start_timestamp = moment(item.datetime_start, "DD-MM-YYYY HH:mm").valueOf()
              let item_end_timestamp = moment(item.datetime_end, "DD-MM-YYYY HH:mm").add(second, 'seconds').valueOf()
              // console.log(item_end_timestamp)
              if ( item.hide == false && 
                ( item.datetime_start == null || item.datetime_start === '' || item_start_timestamp <= current_time) &&
                ( item.datetime_end == null || item.datetime_end === '' || item_end_timestamp > current_time)) {
                this.items_to_show.push(item)
              }
          }
        }else{
          Vue.set(this, 'items_to_show', this.content_obj.items)
        }
      },
      linkContent(item){
        var link_content = ''
        if (item.use_rentracks_link == true && item.rentracks_link !== ''){
          link_content = item.rentracks_link
        }else{
          link_content = item.product_link
        }
        if (link_content === ''){
          link_content = '#'
        }
        return link_content
      },
      linkTarget(item){
        if (item.open_link_in_new_tab){
          return '_blank'
        }
        return ''
      },
      show_all(block_id) {
        var elements = document.querySelectorAll('.voucher-' + block_id + ' .voucher-item.d-none')
        for (var ele of elements) { ele.classList.remove('d-none'); }
        document.querySelector('.btn-load-more-voucher-' + block_id).classList.add('d-none')
      },
      addBlock(){ this.$emit("add_block", this.block_index + 1) },
      editBlock(){ this.$emit("edit_block", this.block_index) },
      toogleBlock(){
        var value = 0
        if (this.block_data.hide == 0){ value = 1 } else { value = 0 }
        this.$emit("update_block", {'block_index': this.block_index, 'block_id': this.block_data.block_id, 'hide': value})
      },
      moveBlockUp(){
        if (this.block_index > 0){ this.$emit("move_up", this.block_index) }
      },
      moveBlockDown(){
        if (this.block_index < this.number_of_blocks){ this.$emit("move_down", this.block_index) }
      },
      deleteBlock(){ this.$emit("delete_block", this.block_index) },

      copyVoucher(text, view_id){
        this.copyToClipboard(text, view_id)
      },
      copyToClipboard(text, view_id){
        var el = document.createElement('textarea');
        el.value = text;
        el.setAttribute('readonly', '')
        el.style = {position: 'absolute', left: '-9999px'}
        document.getElementById(view_id).appendChild(el)
        el.select()
        document.execCommand('copy')
        document.getElementById(view_id).removeChild(el)
        this.$toast.open({message: this.$t("copied"), type: 'success', position: 'top'})
      },
      detailVoucher(item){
        this.current_view_voucher = JSON.parse(JSON.stringify(item))
        var modal = document.getElementById('voucherDetailModal-'+this.block_data.block_id)
        modal.addEventListener('show.bs.modal', () => { document.body.classList.add('voucher-detail-modal') });
        modal.addEventListener('hide.bs.modal', () => { document.body.classList.remove('voucher-detail-modal') });
        modal.addEventListener('hidden.bs.modal', () => {
          document.querySelector('#voucherDetailModal-'+this.block_data.block_id+' .modal-dialog').classList.remove('animate__zoomOut')
          document.querySelector('#voucherDetailModal-'+this.block_data.block_id+' .modal-dialog').classList.add('animate__zoomIn')
        }); 
        
        this.copyVoucher(item.voucher_code, 'app')
        setTimeout(()=>{ bootstrap.Modal.getOrCreateInstance(modal).show() }, 200)
      },
      closeDetailVoucherModal(){
        document.querySelector('#voucherDetailModal-'+this.block_data.block_id+' .modal-dialog').classList.remove('animate__zoomIn')
        document.querySelector('#voucherDetailModal-'+this.block_data.block_id+' .modal-dialog').classList.add('animate__zoomOut')
        setTimeout(()=>{ bootstrap.Modal.getOrCreateInstance(document.getElementById('voucherDetailModal-'+this.block_data.block_id)).hide() }, 100)
      },
      gotoPage(){
        var link = this.linkContent(this.current_view_voucher)
        var target = this.linkTarget(this.current_view_voucher)
        window.open(link, target);
      },
      input_search_change(){
        var search_term = document.getElementById('input-search-voucher-' + this.block_data.block_id).value
        if (search_term.length == 0){ this.search_voucher() }
      },
      search_voucher(){
          Vue.set(this, 'items_to_show', [])
          let search_term = document.getElementById('input-search-voucher-' + this.block_data.block_id).value
          search_term = utils.removeDiacritics(search_term).toLowerCase()
          for(let i = 0; i < this.content_obj.items.length ; i++){
              let current_time = Date.now()
              const item = this.content_obj.items[i]
              let item_start_timestamp = moment(item.datetime_start, "DD-MM-YYYY HH:mm").valueOf()
              if (this.content_obj.hide_expired_voucher == true){
                var time = ''
                if (item.datetime_end !== ''){
                  time = item.datetime_end.split(' ')
                }
                var second = 0
                if (time === "23:59"){ second = 59 }

                let item_end_timestamp = moment(item.datetime_end, "DD-MM-YYYY HH:mm").add(second, 'seconds').valueOf()
                if ( item.hide == false && 
                  ( item.datetime_start == null || item.datetime_start === '' || item_start_timestamp <= current_time) &&
                  ( item.datetime_end == null || item.datetime_end === '' || item_end_timestamp > current_time) &&
                  (utils.removeDiacritics(item.voucher_code).toLowerCase().includes(search_term) || utils.removeDiacritics(item.voucher_name).toLowerCase().includes(search_term)) ){
                  this.items_to_show.push(item)
                }
              }else{
                 if ( item.hide == false &&
                  ( item.datetime_start == null || item.datetime_start === '' || item_start_timestamp <= current_time) &&
                  ( item.datetime_end == null || item.datetime_end === '' )) {
                  this.items_to_show.push(item)
                }
              }
          }
          let count_results = this.items_to_show.length
          
          if (count_results == 0){
            if (search_term.length == 0){
              this.no_results = false
            }else{
              this.no_results = true
            }
          }else{ 
            this.no_results = false 
          }
      },
    },
    watch: {
      blocks: {
        handler(){
          this.initData()
        },
        deep: true
      }
    }
};
</script>

<style>
.voucher-wrapper .voucher-item{
  cursor: pointer;
  text-decoration: none;
  background-color: #fff;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 7%);
  font-family: var(--mypage-paragraph-font-family);
}
.voucher-wrapper .voucher-item a{
  text-decoration: none;
}
.voucher-wrapper .voucher-item .voucher-image{
  width: 100%;
  height: auto;
  background-size: cover;
  aspect-ratio: 1 / 1;
  border-radius: 4px;
  margin: 0px auto;
  object-fit: cover;
  outline-offset: -1px;
}

.voucher-wrapper .voucher-item .voucher-name{
  font-size: 13px;
  line-height: 13px;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  letter-spacing: -0.2px;
  color: var(--mypage-paragraph-color);
  font-family: var(--mypage-paragraph-font-family);
}

.btn-copy-voucher {
  font-size: 12px;
  padding: 0.3rem 0;
  font-family: var(--mypage-paragraph-font-family); 
  color:var(--mypage-button-text-color); 
  border: var(--mypage-button-border-width) solid var(--mypage-button-border-color); 
  border-radius: var(--mypage-button-border-radius); 
  font-weight: var(--mypage-button-text-font-weight); 
  line-height: var(--mypage-button-text-line-height); 
  background-color: var(--mypage-button-background-color); 
  box-shadow: var(--mypage-button-box-shadow);
  cursor: pointer;
}

.btn-copy-voucher-lg {
  font-size: var(--mypage-button-text-size);
  font-family: var(--mypage-paragraph-font-family); 
  color: var(--mypage-button-text-color); 
  border: var(--mypage-button-border-width) solid var(--mypage-button-border-color); 
  border-radius: var(--mypage-button-border-radius); 
  font-weight: var(--mypage-button-text-font-weight); 
  line-height: var(--mypage-button-text-line-height); 
  background-color: var(--mypage-button-background-color); 
  box-shadow: var(--mypage-button-box-shadow);
  cursor: pointer;
}
.btn-copy-voucher-outline-lg {
  font-size: var(--mypage-button-text-size);
  font-family: var(--mypage-paragraph-font-family); 
  color: var(--mypage-button-background-color);
  border: var(--mypage-button-border-width) solid var(--mypage-button-border-color); 
  border-radius: var(--mypage-button-border-radius); 
  font-weight: var(--mypage-button-text-font-weight); 
  line-height: var(--mypage-button-text-line-height); 
  box-shadow: var(--mypage-button-box-shadow);
  background-color: var(--mypage-button-text-color);;
  cursor: pointer;
}
.voucher-wrapper .voucher-item a{
  color: var(--mypage-paragraph-color)!important;
}
#voucherDetailModal {
  font-family: var(--mypage-paragraph-font-family); 
}
#voucherDetailModal .voucher-image{
  width: 100%;
  height: auto;
  background-size: cover;
  aspect-ratio: 1 / 1;
  border-radius: 4px;
  margin: 0px auto;
  object-fit: cover;
  outline-offset: -1px;
}
.block-voucher .input-group .btn{
  z-index:0;
}
.not-found-voucher{
  font-family: var(--mypage-paragraph-font-family);
  color: var(--mypage-paragraph-color);
  font-size: calc(var(--mypage-paragraph-font-size)/var(--mypage-paragraph-ratio));
  margin-top: 0.25rem; 
}
#button-copy-voucher-modal-detail {
  border-color: #ced4da;
}
#button-copy-voucher-modal-detail:hover{
  border-color: #6c757d;
}
</style>